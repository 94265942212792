<template>
  <v-banner
>Добро пожаловать на страницу администратирования</v-banner>
</template>


<script>
export default {
  name : 'MainComponent',

}
</script>
